@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import "grid-bg";

html {
    font-size: 20px;
    min-height: 100vh;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: var(--mantine-font-family);
    scroll-behavior: smooth;
    scroll-padding-top: 4rem;
}

a {
    color: inherit;
    text-decoration: none;
}

p {
    margin: 0;
}

.rubik {
    font-family: 'Rubik', sans-serif !important;
}

.ofv {
    overflow: visible !important;
}

.nosel {
    user-select: none !important;
}

.flg {
    flex-grow: 1;
}

@layer utilities {
    .base-border {
        border: 0.0625rem solid var(--mantine-color-gray-3);
    }
}

/* @media (prefers-color-scheme: dark) {
    html {
        color-scheme: dark;
    }

    body {
        color: white;
        background: black;
    }
} */

/* .hoverScale {
    transform: scale(1.01);
} */