.tile {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    padding: var(--mantine-spacing-md);

    border: 2px solid var(--mantine-color-gray-1);
    border-radius: var(--mantine-radius-md);
    transition: background-color 0.1s, border-color 0.1s;
    cursor: pointer;
}

.tile:hover,
.tile:focus {
    background-color: var(--bgColor);
    border-color: var(--mainColor);
}

.tile.expanded {
    padding: var(--mantine-spacing-md) var(--mantine-spacing-xl);
}

.cartQuantity {
    opacity: 0.3 !important;
    transition: opacity 0.1s;
}

.cartQuantity:hover,
.cartQuantity.notEmpty {
    opacity: 1 !important;
}

.pin {
    opacity: 0.3;
    transition: opacity 0.1s;
}

.pin:hover {
    opacity: 1;
}